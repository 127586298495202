import React from 'react';

function BtnOutlet({ url,img }) {
    return (
        <>
            <div className="col-12 pb-5">
              <a href={url}><img className='img-fluid mx-auto w-btn' src={img} alt=''></img></a>
            </div>
        </>
    )
}

export default BtnOutlet