import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import LP from './landingpage';

function App() {
  
  return (
    <div className="App shadow h-100">
      <LP/>
    </div>
  );
}

export default App;