import React from 'react';
import BTN from './components/LP/btn-outlet';

function LandingPage() {


  return (
    <div className='h-100'>
      {
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 px-0">
              <img className='img-fluid w-logo' src="img/logo.png" alt=''></img>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 px-0 text-center">
              <img className='img-fluid middle-banner' src="img/banner.png" alt=''></img>
            </div>
          </div>
          <div className="row pb-3 text-center">
            <BTN url="https://apps.apple.com/ae/app/operation-falafel/id1485183985" img="img/of-ios.png" />
            <BTN url="https://play.google.com/store/apps/details?id=app.android.operation.falafel" img="img/of-android.png" />
          </div>
        </div>
      }
    </div>
  );
}

export default LandingPage